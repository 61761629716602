// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@use 'sass:math';

/* UI library styles */
@import 'framework/config';

:root {
  --ion-default-font: var(--t-font-family--primary) !important;
  --ion-default-font--bold: var(--t-font-family--accent);

  /** Ionic CSS Variables **/
  /** primary **/
  --ion-color-primary: #009c39;
  --ion-color-primary-main: #009c39;
  --ion-color-primary-rgb: 0, 156, 57;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #008932;
  --ion-color-primary-tint: #1aa64d;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #91cb5f;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /*
   * Mediacoach Mobile
   * -------------------------------------------
   */

  /** Color Brand **/
  --mcm-color__brand--primary: #{$c-color__brand--primary};
  --mcm-color__brand--tertiary: #{$c-color__brand--tertiary};
  --mcm-color__brand--live: #6dd400;
  --mcm-color__brand-success: #73c021;
  --mcm-color__brand-warning: #f7b500;
  --mcm-color__brand-danger: #ef4949;
  --mcm-color__brand-info: #5ca8ff;
  --mcm-color__brand--list-live-rgb: 0, 156, 53;

  /** Color Neutral **/
  --mcm-color__neutral--grey--02: #{$c-color__neutral--grey--02};
  --mcm-color__neutral--grey--05: #{$c-color__neutral--grey--05};
  --mcm-color__neutral--grey--10: #{$c-color__neutral--grey--10};
  --mcm-color__neutral--grey--15: #aeaeae;
  --mcm-color__neutral--grey--20: #{$c-color__neutral--grey--20};
  --mcm-color__neutral--grey--40: #{$c-color__neutral--grey--40};
  --mcm-color__neutral--grey--70: #{$c-color__neutral--grey--70};
  --mcm-color__neutral--grey--80: #{$c-color__neutral--grey--80};
  --mcm-color__neutral--white: #{$c-color__neutral--white};
  --mcm-color__neutral--white--transparent: #{rgba($c-color__neutral--white, 0)};
  --mcm-color__neutral--black: #{$c-color__neutral--black};

  --mcm-color__grey-dark: #{$c-color__neutral--grey--75};
  --mcm-color__grey-dark-1: #{$c-color__neutral--grey--65};
  --mcm-color__grey-dark-2: #{$c-color__neutral--grey--45};
  --mcm-color__grey-dark-3: #{$c-color__neutral--grey--20};

  /** Color Extras **/
  --mcm-color__gradient-initial: #29a533;
  --mcm-color__gradient-final: #91cb5f;
  --mcm-color__gradient-initial--grey: #f6f7f7;
  --mcm-color__gradient-final--grey: #eaebec;
  --mcm-color-match-state--delayed: #f6b027;
  --mcm-color-match-state--abandoned: #da4a5c;
  --mcm-color-match-detail--incidence: #dd2727;
  --mcm-color-match-detail--incidence-background: rgba(221, 39, 39, 0.1);
  --mcm-color-lineup--warning: #de9f40;

  /** Spacing **/
  --mcm-space-xxs: #{$c-space--xxs}; // 1px
  --mcm-space-xs: #{$c-space--xs}; // 2px
  --mcm-space-sm: #{$c-space--sm}; // 4px
  --mcm-space-md: #{$c-space--md}; // 8px
  --mcm-space-ml: #{$c-space--ml}; // 12px
  --mcm-space-lg: #{$c-space--lg}; // 16px
  --mcm-space-xl: #{$c-space--xl}; // 24px
  --mcm-space-xxl: #{$c-space--xxl}; // 32px

  /** Size**/
  --mcm-size--xxs: #{$c-size--xxs}; // 8px
  --mcm-size--xs: #{$c-size--xs}; // 16px
  --mcm-size--sm: #{$c-size--sm}; // 32px
  --mcm-size--md: #{$c-size--md}; // 56px
  --mcm-size--ml: #{$c-size--ml}; // 80px
  --mcm-size--lg: #{$c-size--lg}; // 128px
  --mcm-size--xl: #{$c-size--xl}; // 256px
  --mcm-size--xxl: #{$c-size--xxl}; // 512px

  /** Font Size **/
  --mcm-font-size--xxxs-init: #{$c-font-size--xxxs}; // 8px
  --mcm-font-size--xxxs: #{$c-font-size--xxxs + 1}; // 9px
  --mcm-font-size--xxs: #{$c-font-size--xxs - 1}; // 10px
  --mcm-font-size--xs: #{$c-font-size--xs}; // 12px
  --mcm-font-size--sm: #{$c-font-size--sm}; // 14px
  --mcm-font-size--md: #{$c-font-size--md}; // 16px
  --mcm-font-size--lg: #{$c-font-size--lg}; // 18px
  --mcm-font-size--xl: #{$c-font-size--xl}; // 24px
  --mcm-font-size--xxl: #{$c-font-size--xxl}; // 32px
  --mcm-font-size--xxxl: #{$c-font-size--xxxl}; // 48px

  /** Border Radius **/
  --mcm-border-radius: #{$c-border-radius}; // 4px
  --mcm-border-radius--none: #{$c-border-radius--none}; // 0px
  --mcm-border-radius--xs: #{math.div($c-border-radius--sm, 2)}; // 2px
  --mcm-border-radius--sm: #{$c-border-radius--sm}; // 4px
  --mcm-border-radius--md: #{$c-border-radius--md}; // 8px
  --mcm-border-radius--lg: #{$c-border-radius--lg}; // 12px
  --mcm-border-radius--ellipsis: #{$c-border-radius--ellipsis}; // 50%
  --mcm-border-radius--rounded: #{$c-border-radius--rounded}; // 1000px

  /** Border **/
  --mcm-border-grey: #{$c-border};

  /** Animations **/
  --mcm-duration: #{$c-duration};
  --mcm-duration--fast: #{$c-duration--fast};

  /** Others **/
  --mcm-opacity--disabled: 0.3;
  --mcm-opacity--disabled--dark: 0.7;
  --mcm-header--min-height: 96px;

  /** Gradient **/
  --mcm-gey-gradient: linear-gradient(to bottom, #f6f7f7, #eaebec);
}

/** Ionic Dark Mode Variables **/
// @import 'dark-mode'; Uncomment to activate
