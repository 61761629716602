/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Overwrites */

/* UI library styles */
@import 'theme/config';
@import 'framework/tools';
@import 'framework/config';
@import 'framework/patterns';
@import 'framework/utils';
@import 'framework/iconography/main';
@import 'framework/animations/main';
@import 'framework/themes/theme-lato';
@import '@angular/cdk/overlay-prebuilt.css';

/* Other */
ion-modal.mcm-metric-detail__info-modal {
  --backdrop-opacity: 0.5 !important;
  --max-height: 378px;
  --max-width: 343px;
  --border-radius: calc(var(--mcm-border-radius) + 2px);

  &::part(content) {
    top: calc(var(--mcm-size--md) - 1px);
    position: absolute;
  }
}

.modal-default:first-of-type,
ion-popover:first-of-type {
  --backdrop-opacity: 0.5;
}

.custom-modal {
  --mcm-modal-wrapper-width: 300px;
  --mcm-modal-wrapper-hight: 300px;
  --mcm-modal-wrapper-border-radius: 6px;
  --mcm-modal-wrapper-player-top: 119px;
  --mcm-modal-wrapper-team-top: 89px;

  &::part(content) {
    width: var(--mcm-modal-wrapper-width);
    height: var(--mcm-modal-wrapper-hight);
    border-radius: var(--mcm-modal-wrapper-border-radius);
  }

  &--player {
    &::part(content) {
      top: var(--mcm-modal-wrapper-player-top);
      position: absolute;
    }

    &-dual {
      &::part(content) {
        height: calc(var(--mcm-modal-wrapper-hight) + 50px);
      }
    }
  }

  &--team {
    &::part(content) {
      top: var(--mcm-modal-wrapper-team-top);
      position: absolute;
    }

    &-dual {
      &::part(content) {
        height: calc(var(--mcm-modal-wrapper-hight) + 50px);
      }
    }
  }
}

// FIXME Need to clean ALL gradients app-wide
.mcm-separator {
  height: calc(var(--mcm-space-xxs) + var(--mcm-space-xs));
  width: calc(var(--mcm-size--xxs) + var(--mcm-size--sm));
  margin: auto;
  background-image: linear-gradient(to right, var(--mcm-color__gradient-initial), var(--mcm-color__gradient-final) 48%);
}

ion-searchbar.mcm-search-bar {
  padding: 0;
  border-radius: calc(var(--mcm-border-radius--sm) + 1px);
  --box-shadow: none;
  --border-radius: calc(var(--mcm-border-radius--sm) + 1px);
  --placeholder-color: var(--mcm-color__grey-dark-2);
  --placeholder-font-style: italic;
  border: var(--mcm-space-xxs) solid var(--mcm-color__neutral--grey--20);

  &:focus-within {
    border-color: transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(white, white),
      linear-gradient(63deg, var(--mcm-color__gradient-initial) 5%, var(--mcm-color__gradient-final) 96%);
  }

  input.searchbar-input {
    font-size: var(--mcm-font-size--xs);
    padding-right: var(--mcm-space-xl);
  }
}

ion-content {
  --offset-bottom: 0px !important;
}

app-button {
  outline: none;
}

[class^='u-icon'],
[class*=' u-icon'] {
  &:not(.u-icon--xs):not(.u-icon--sm):not(.u-icon--md):not(.u-icon--lg):not(.u-icon--xl):not(.u-icon--xll)::before {
    font-size: calc(var(--mcm-size--sm) + var(--mcm-space-sm));
  }
}

.p-tooltip {
  font-size: $c-space--md + $c-space--xxs;
  font-family: $c-font-family--accent;
  z-index: $c-z-index--5 !important;

  .p-tooltip-text {
    padding: $c-space--sm - $c-space--xxs $c-space--md - $c-space--xxs;
    background-color: $c-color__neutral--grey--70;
  }

  .p-tooltip-arrow {
    display: none;
  }
}

ion-content {
  transition: padding-top ease 0.4s;
  &::part(scroll) {
    overscroll-behavior: none !important;
    overscroll-behavior-block: none !important;
  }
}

ion-popover {
  --offset-y: #{-($c-space--xxxl + $c-space--xxl + $c-space--lg)};
}

ion-toast.success-toast {
  --background: #{$c-color__brand--primary--dark};
  --color: #{$c-color__neutral--white};
}


ion-toast.error-toast {
  --background: #{$c-color__game--abandoned};
  --color: #{$c-color__neutral--white};
}
